import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/Seo';
import Hero from '../../../components/Hero';
import TeamCard from '@components/TeamCard';
import { graphql } from 'gatsby';
import { Divider, Grid, Row } from '@components/Grid';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import CustomSwiper from '@components/CustomSwiper';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import { SwiperSlide } from 'swiper/react';
import Img from 'gatsby-image';
import ProjectCard from '@components/ProjectCard';
import styles from '../styles.module.scss';

const sylvanArmsPartsCatalogue = ({ data }) => {

    const sliderImages = [
        data?.slider01?.childImageSharp?.fluid,
        data?.slider02?.childImageSharp?.fluid,
        data?.slider03?.childImageSharp?.fluid,
        data?.slider04?.childImageSharp?.fluid,
        data?.slider05?.childImageSharp?.fluid,
    ];

    return (
        <Layout>
            <SEO
                title='Sylvan Arms Parts Catalogue • Projects'
                image={'place image here'}
                description='Creating parts diagrams and artwork in innovative ways.'
            />

            <Hero
                title={'Sylvan Arms Parts Catalogue'}
                // previousCategoryHeading={'Previous Mini Case Study'}
                // previousCategoryLink={'/'}
                // nextCategoryHeading={'Next Mini Case Study'}
                // nextCategoryLink={'/'}
            />

            <div className={styles.caseStudyWrapper}>


                <ProjectOverview
                    client='Sylvan Arms'
                    name='Creating Product breakdowns'
                    backgroundColor='#FFFFFF'
                    typeInverse={true}
                    description={
                        <p>
                            Sylvan Arms required custom illustrative breakdowns of their product lineup to demonstrate
                            the innovation and complexity of their well-built firearm products.
                        </p>
                    }
                />

                <Divider margin={5} />

                <Grid>
                    <Row>
                        <div className='w-full md:w-1/3 mb-4 pr-4'>
                            <ProjectCard
                                name={'Creating the model'}
                                programUsed={'Blender 3D'}
                                description={'The models were the exact CAD models used for Sylvan Arms’ product. This allowed for accurate illustrations'}
                                image={data?.progress01?.childImageSharp?.fluid}
                            />
                        </div>

                        <div className='w-full md:w-1/3 mb-4 pr-4'>
                            <ProjectCard
                                name={'Animating the product'}
                                programUsed={'3D Animation'}
                                description={'When the models were separated into their exploded view, they were then animated in the 3D space using keyframes.'}
                                image={data?.progress02?.childImageSharp?.fluid}
                            />
                        </div>


                        <div className='w-full md:w-1/3 mb-4 pr-4'>
                            <ProjectCard
                                name={'Exporting SVGS'}
                                programUsed={'Illustrator'}
                                description={'Using an experimental part of Blender, we exported all the outlines of the model as SVG. This achieved the brand look Sylvan Arms’ is looking for.'}
                                image={data?.progress03?.childImageSharp?.fluid}
                            />
                        </div>
                    </Row>

                    <Divider margin={5} />

                    <h2>Working Smart</h2>
                    <p>Element Softworks employed the innovative use of experimental features in a 3D modelling program
                        to deliver the artwork to the client faster. Overall, creating vector illustrations by hand
                        would of been costly, where as, creating a 3D model to automatically generate them was a large
                        initial outlay but provided unlimited angles, scenes and use cases for Sylvan Arms.</p>

                </Grid>

                <Divider margin={5} />

                <CustomSwiper>
                    {sliderImages?.map((image) => (
                        <SwiperSlide key={image?.src}>
                            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>

                <KnowMoreCta
                    text={
                        <h2>
                            Want to know more about <br /> our Branding services?
                        </h2>
                    }
                    buttonText='Read more'
                    link='/service/graphic-design/branding-strategy'
                />
            </div>

        </Layout>
    );
};

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        progress01: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-blender-screenshot.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        progress02: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-wip-folding-stock.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        progress03: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-wip-ar-iso.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slider01: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-folding-stock.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slider02: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-cleaning-mat.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slider03: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-ar-iso.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slider04: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-ar-side.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slider05: file(absolutePath: { regex: "/case-studies-mini-sylvan-arms-9mm-conversion.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`;

export default sylvanArmsPartsCatalogue;
