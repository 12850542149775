import React, { useState } from 'react';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import { Motion, spring } from 'react-motion';
import Squiggle from '@images/svgs/squiggle.svg';


const ProjectCard = ({ name, programUsed, description, image }) => {
    const [maxHeight, setMaxHeight] = useState(130);
    const [marginTop, setMarginTop] = useState(0);

    return (
        <div
            className={`bg-white w-full h-full ${styles.projectCard}`}
            onMouseEnter={() => {
                setMaxHeight(350);
                setMarginTop(-220);
            }}
            onMouseLeave={() => {
                setMaxHeight(130);
                setMarginTop(0);
            }}
        >
            <div>
                {!!image && (
                    <div className={styles.projectCardImage}>
                        <Img fluid={image} />
                    </div>
                )}
                <Motion style={{ maxHeight: spring(maxHeight), marginTop: spring(marginTop) }}>
                    {({ maxHeight, marginTop }) => (
                        <div
                            className={styles.projectCardContentWrapper}
                            style={{ maxHeight, marginTop }}
                        >
                            <div className={`${styles.projectCardContent} p-6`}>
                                <h3>{name}</h3>
                                <p className={styles.projectCardProgramText}>{programUsed}</p>
                                <Squiggle style={{ maxWidth: 50 }} />
                                <p className='mt-8'>{description}</p>
                            </div>
                        </div>
                    )}
                </Motion>
            </div>
        </div>
    );
};

export default ProjectCard;
