import React from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

const ProjectOverview = ({ client, name, description, backgroundColor, typeInverse }) => {
    return (
        <section className={styles.projectOverviewWrapper} style={{ background: backgroundColor }}>
            <div className={typeInverse ? styles.typeBlack : styles.typeWhite}>
                <Grid>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={styles.overviewSection}>
                                <div>
                                    <h3>Client</h3>
                                    <p>{client}</p>
                                </div>
                                <Divider margin={5} />
                                <div>
                                    <h3>Purpose</h3>
                                    <p>{name}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Divider xsMargin={5} smMargin={0} />
                            <div className={styles.overviewSection}>
                                <h3>Project</h3>
                                {description}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </section>
    );
};

export default ProjectOverview;
